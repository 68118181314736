import Home from './components/Home'
import Aboutus from './components/Aboutus'
import Header from './components/Header'
import Footer from './components/Footer'
import Yootab from './components/Yootab'
import Tokenomics from './components/Tokenomics'
import Scream from './components/Scream'

function App() {
  return (
    <div className="App">
      <Header />
      <Home />
      <Scream />
      <Aboutus />
      <Yootab />
      <Tokenomics />
      <Footer />

    </div>
  );
}

export default App;
